<template>
  <div>
    <b-form-group label-for="user-email">
      <b-form-input
        id="user-email"
        v-model="options.email"
        placeholder="Recherche par email"
        autofocus
        @change="updateFilters()"
      />
    </b-form-group>
    <b-form-group label-for="input-status">
      <b-form-select
        v-model="options.status"
        placeholder="Statut"
        @change="updateFilters()"
      >
        <template #first>
          <b-form-select-option :value="null">
            -- Filtrer par status --
          </b-form-select-option>
        </template>
        <b-form-select-option
          v-for="option in ['CLOSED', 'RECIEVED']"
          :key="option"
          :value="option"
        >
          {{ $t(`PAYMENT.${option}`) }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group label-for="input-transaction_status">
      <b-form-select
        v-model="options.transaction_status"
        placeholder="Statut de la transaction"
        @change="updateFilters()"
      >
        <template #first>
          <b-form-select-option :value="null">
            -- Filtrer par statut du paiement --
          </b-form-select-option>
        </template>
        <b-form-select-option
          v-for="option in ['PAID', 'UNPAID']"
          :key="option"
          :value="option"
        >
          {{ $t(`PAYMENT.${option}`) }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group label-for="input-object_type">
      <b-form-select
        v-model="options.object_type"
        placeholder="Type"
        @change="updateFilters()"
      >
        <template #first>
          <b-form-select-option :value="null">
            -- Filtrer par type --
          </b-form-select-option>
        </template>
        <b-form-select-option
          v-for="option in ['ORDER', 'USER_SUBSCRIPTION']"
          :key="option"
          :value="option"
        >
          {{ $t(`PAYMENT.${option}`) }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group label-for="input-object_type">
      <b-form-datepicker
        v-model="options.start_at"
        locale="fr-FR"
        placeholder="Du"
        @input="updateFilters()"
      />
    </b-form-group>
    <b-form-group label-for="input-object_type">
      <b-form-datepicker
        v-model="options.end_at"
        locale="fr-FR"
        placeholder="Au"
        @input="updateFilters()"
      />
    </b-form-group>

    <!-- <pre>{{ options }}</pre> -->
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'UserFilters',
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: {
        email: null,
        status: null,
        transaction_status: null,
        object_type: null,
        start_at: null,
        end_at: null,
      },
    }
  },
  created() {
    this.options = Object.assign(this.options, this.filters)
  },
  methods: {
    updateFilters() {
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: Object.assign(this.options, { page: 1 }),
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
  },
}
</script>

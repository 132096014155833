<template>
  <b-card title="">
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1 d-none"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <button
            class="btn btn-info btn-icon"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <payments-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Ajouter un client</h3>
      <user-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <p v-if="payments.length == 0" class="text-center text-muted">
      <em>Aucun résultat</em>
    </p>
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <th>Le</th>
          <th>#</th>
          <th>Montant</th>
          <th>Statut</th>
          <th>Paiement</th>
          <th>Type</th>
          <th>Client</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="payment in payments">
          <tr :key="payment.id">
            <td class="p-1">
              {{ $dayjs(payment.created_at).format('DD-MM-YYYY') }}
            </td>
            <td>
              <span v-if="payment.transaction_id" class="text-nowrap">
                <copy-value :value="payment.transaction_id.slice(0, 7)" />…
              </span>
            </td>
            <td>
              {{ payment.amount | currency }}
            </td>
            <td>
              {{ $t(`PAYMENT.${payment.status}`) }}
            </td>
            <!-- <td>
              {{ $t(`PAYMENT.${payment.transaction_type}`) }}
            </td> -->
            <td>
              <span
                :class="{
                  'text-danger': payment.transaction_status == 'UNPAID',
                }"
              >
                {{ $t(`PAYMENT.${payment.transaction_status}`) }}
              </span>
            </td>
            <td>
              <router-link
                v-if="payment.object_type == 'ORDER'"
                :to="`/orders/${payment.object_id}`"
              >
                {{ $t(`PAYMENT.${payment.object_type}`) }}
              </router-link>
              <router-link
                v-if="payment.object_type == 'USER_SUBSCRIPTION'"
                :to="`/subscriptions/${payment.object_id}`"
              >
                {{ $t(`PAYMENT.${payment.object_type}`) }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="
                  payment.payment_data &&
                  payment.payment_data['kr-answer'] &&
                  payment.payment_data['kr-answer'].customer
                "
                :to="`/users/${payment.user_id}`"
              >
                {{ payment.payment_data['kr-answer'].customer.email }}
              </router-link>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <pre>{{ payments }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import PaymentsFilters from './PaymentsFilters.vue'

export default {
  components: {
    BCard,
    PaymentsFilters,
  },
  data() {
    return {
      displayFilters: false,
      displayForm: false,
      payments: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 25,
        status: null,
        transaction_status: null,
        object_type: null,
        start_at: null,
        end_at: null,
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      if (this.filters.term) {
        this.displayFilters = true
      }
      const resp = await this.$http.get('/payments/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields':
            'results{*,payment_data{kr-answer{transactions{amount,paymentMethodType,detailedStatus},customer}}},meta',
        },
      })
      // console.log(JSON.parse(JSON.stringify(resp.data.results)))
      this.payments = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(payment) {
      this.displayForm = false
      this.$router.push(`/payments/${payment.id}/`)
    },
  },
}
</script>

<style></style>
